export const EDColors = {
    topbar: "#FFFFFF",
    white: "#FFFFFF",
    pgbar: "#00cbd6",
    pgbarbk: "#4d577b",
    borderBtn: "#000d3f",

    primary: "#242424",
    secondary: '#E62A28',
    third: '#333333',
    fourth: '#213388',
    lightGray: '#ebecf0',
    lightBlue: '#2A32FF',
    text: '#010A41',
    passText: '#5F795B',
    progress: ['#213388', '#213388', '#213388'],
    progress2: ['#E62A28', '#E62A28', '#E62A28'],



    itembdr: "#cbced8",
    green: "#00b674",
    blue: "#1b05a8",
    bggreen : "#c0fdf3",
    red: "#d12223",
    bgred: "#fff5f6",
    unselect: "#ffe2e1",
    fbbk: "#0049fd",
    weeklightgrey: "#f2f3f5",
    trans: "transparent",
    acttoggle: "#00f6db",
    tint: "#0496c1",
    grey: "grey",
    lightgrey: "lightgrey",
    descback: "#064667",
    reviewbk: "#c9f9f7",
    poolbk: "#211D51",
    poolitem: "#f5fafd",
    splashbk: "#010a41",
    underline: "blue",
    placeclr: "rgba(0, 200, 200, .5)",
    warning: "rgba(200, 10, 20, 1)",
    sepline: "rgba(0, 187, 212, .3)",
    modalbk : "rgba(0, 0, 0, .3)",
    gradient: ["#E62A28", "#E62A28", "#E62A28"],
}