import * as firebase from "firebase/app";
import 'firebase/auth';

const firebaseConfig = {

  apiKey: "AIzaSyD9_MiWpIejxaGd8cGVOvQI96fN4RotWcE",
  authDomain: "american-radio-club.firebaseapp.com",
  databaseURL: "https://american-radio-club-default-rtdb.firebaseio.com",
  projectId: "american-radio-club",
  storageBucket: "american-radio-club.appspot.com",
  messagingSenderId: "1029443371652",
  appId: "1:1029443371652:web:2724e782b619f53a5778d1",
  measurementId: "G-XX233B1LGF"
};

// Initialize Firebase
// let Firebase;

// if (firebase.apps.length === 0) {
//   Firebase = firebase.initializeApp(firebaseConfig);
// }
firebase.initializeApp(firebaseConfig);

export const tableNames = {
  level: 'Levels-Web',
  question: 'Questions',
  section: 'Sections'
}

export const authInfo = {
  email: 'test@user.com',
  password: 'testuser'
}

// export default Firebase;
