import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';
// import { Assets } from '../constants/Images';

const GradientButton3 =({text, handlePress})=> {

    return (
        <TouchableOpacity style={styles.mainBtn} onPress = {() => handlePress()}>
            <LinearGradient
                start={{x: 0, y: 0}} end={{x: 1, y: 1.0}}
                style={styles.button}
                colors={EDColors.gradient}
            >
                <Text style={styles.btnTxt}>{text.toUpperCase()}</Text>
                {/* <Image source={Assets.next} style={styles.next}/> */}
            </LinearGradient>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    
    mainBtn: {
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        borderRadius: 25,
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
    },
    button: {
        flexDirection: 'row',
        height: 50,
        paddingHorizontal: 20,
        width: "100%",
        minWidth: 260,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.big,
        letterSpacing: 5,
        color: EDColors.white
    },
    next: {
        width: 15,
        height: 20,
        marginLeft: 10,
    }
})
export default GradientButton3;