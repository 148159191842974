
import firebase from "firebase/app";
import "firebase/firestore";

import { tableNames } from '../constants/Config';

import { Units } from '../constants/Unit';

// ====================  Start getting level data  ======================
export function getLevel() {
    return new Promise((resolve, reject) =>{
        try{
            firebase.firestore().collection(`${tableNames.level}`).orderBy("LevelId", "asc").get().then( querySnapshot => {
                let level = [];
                let count = 0;
                if( querySnapshot !== null ){
                    querySnapshot.forEach(async documentSnapshot => {
                        count++;
                        level.push({
                            id: documentSnapshot.id,
                            ...documentSnapshot.data(),
                        });
                        if( count === querySnapshot.size ){
                            resolve(level);
                        }
                    });
                }
            }).catch((error) => {
                console.log("error ::", error)
                reject(error.code);
            });
        } catch (err){
            reject(err);
        }
    });
}

// start getting question data by level id 
export const getQuesDataForPracticeByLevel = (selectedLevel) => {

    return new Promise((resolve, reject) => {
        if( selectedLevel === undefined || selectedLevel === null ){
            resolve([]);
        }
        firebase.firestore().collection(`${tableNames.question}`).where("LevelId", "==", selectedLevel.LevelId).get().then( async querySnapshot => {
            let ques = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async(documentSnapshot) => {
                    const quesitem = documentSnapshot.data();
                    ques.push({
                        id: documentSnapshot.id,
                        ...quesitem,
                        answers: Units.alpha,
                    });
                    count++;
                    if( count === querySnapshot.size ){
                        resolve(ques);
                    }
                });
                if( querySnapshot.size === 0 )
                    reject([]);
            } else {
                reject(null);
            }   
        });
    });
}

export const getSectionData = (selectedLevel) =>{
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.section).where("LevelId", "==", selectedLevel.LevelId).get().then(querySnapshot => {
            let section = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async function( documentSnapshot) {
                    const item = documentSnapshot.data();
                    section.push({
                        id: documentSnapshot.id,
                        ...item,
                    });
                    count++;
                    if( count === querySnapshot.size ){
                        const sortedData = section.sort(function(a, b) {
                            return a.SectionNumber - b.SectionNumber;
                        });
                        resolve(sortedData);
                    }
                });
                if( querySnapshot.size === 0 ){
                    resolve([]);
                }
            } else {
                reject(null);
            }
        });
    });
}
