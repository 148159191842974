import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import Modal from 'react-native-modal';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const ExplainModal = ({ modalVisible, onClose, width }) => {
    return (
        <Modal
            testID={'modal'}
            isVisible={modalVisible}
            onBackdropPress={onClose}
            onSwipeComplete = {onClose}
            swipeDirection={['down']}
            hideModalContentWhileAnimating={true}
            style={[styles.view, {width: width}]}>
                <View style = {styles.container}>
                    <Text style={styles.descTxt}>{"Please answer all questions before finishing the exam."}</Text>
                    <TouchableOpacity style={styles.closeCon} onPress={onClose}>
                        <Text style={styles.closeTxt}>OK</Text>
                    </TouchableOpacity>
                </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    view: {
        justifyContent: 'center',
        margin: 0,
        alignSelf: "center",
    },
    container: {
        backgroundColor: EDColors.white,
        paddingVertical: 10,
        borderRadius: 10,
        alignItems: "center",
    },
    closeCon: {
        paddingVertical: 20,
        marginLeft: Units.isTablet? 40: 20,
        width: 80,
        alignItems: "center",
        borderRadius: 10,
    },
    closeTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.big,
        color: EDColors.primary,
    },
    descTxt: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.medium,
        paddingHorizontal: Units.isTablet? "15%": 20,
        paddingVertical: 10,
        lineHeight: 26,
        marginBottom: Units.isIOS? 20: 0,
    }
});

export default ExplainModal;