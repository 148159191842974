import React from 'react';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';
import { EDColors } from '../constants/Colors';
// import { Assets } from '../constants/Images';
import { Units } from '../constants/Unit';

const ScreenHeader =({text, width, isMobile})=> {

    return (
        <View style = {isMobile ? [styles.imgbk, {height: 150}] : [styles.imgbk]} >
            <Text style = {isMobile? [styles.descTxt, {width: width, fontSize: Units.mobftSizes.extra}] : [styles.descTxt, {width: width}]}>{text}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    imgbk: {
        width:'100%',
        height: 110,
    },
    descTxt:{
        marginTop: 20,
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: Units.ftSizes.extra,
        color: EDColors.primary,
        textAlign: 'center',
        paddingHorizontal: 50,
    }
})
export default ScreenHeader;
