import React from "react";
import { StyleSheet, Text, View } from 'react-native';
import { GradientCircularProgress } from 'react-native-circular-gradient-progress';
import { EDColors } from "../constants/Colors";
import { Units } from "../constants/Unit";
 
const CircularProgressComponent = ({size, width, font, fill}) => {
    return (
        <GradientCircularProgress
            size = {size}
            startColor = {EDColors.fourth}
            middleColor = {EDColors.fourth}
            endColor = {EDColors.fourth}
            progress = {fill}
            emptyColor = {EDColors.lightgrey}
            strokeWidth = {width}
            children = {<View style={style.titleBox}>
                <Text style={[style.title, { fontSize: font }]}>{fill.toFixed(0)}%</Text>
            </View>}
        />
    );
}
 
const style = StyleSheet.create({
    titleBox: {
      ...StyleSheet.absoluteFillObject,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
        fontFamily: Units.ftFamily.KarlaR,
      color: EDColors.third,
      textAlign: 'center',
    },
});
export default CircularProgressComponent;