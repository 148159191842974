/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

 import React from 'react';
 import {
   View,
   StyleSheet,
 } from 'react-native';
 import 'react-native-gesture-handler';
 import { useFonts } from "expo-font";

 import { Provider } from 'react-redux';
 import store from './src/store';
 import MainRoute from './src/Route';
 import { EDColors } from './src/constants/Colors';
import { Units } from './src/constants/Unit';
 
 const App = () => {
    const [loaded] = useFonts(Units.fonts);
    
    if( !loaded ){
        return null;
    }
    return (
        <Provider store = {store}>
            <View style={styles.container}>
                <MainRoute />
            </View>
        </Provider>
    );
 };
 
 const styles = StyleSheet.create({
     container: {
         flex:1,
         backgroundColor: EDColors.topbar,
     },
 });
 
 export default App;
