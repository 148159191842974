import { t } from '../actions/Type';

const initialState = {
    resloading:  false,
    practiceRes: [],
    premiumRes: [],
}

export default function( state = initialState, action ) {
    
    switch( action.type ) {
        case t.LOAD_PRACTICE_RESULT:
            return {
                ...state,
                practiceRes: action.payload,
            };
        case t.LOAD_START:
            return {
                ...state,
                practiceRes: [],
            }
        case t.SET_USER_CLICKED_ON_PREMIUM:
            return {
                ...state,
                premiumRes: action.payload,
            }
        default:
            return state;
    }
}