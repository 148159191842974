import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const OutlineButton =({text, handlePress})=> {

    return (
        <TouchableOpacity style={styles.borderBtn} onPress = {() => handlePress()}>
            <Text style={styles.btnTxt}>{text.toUpperCase()}</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    borderBtn: {
        marginTop: 10,
        height: Units.isTablet? 60: 50,
        width: Units.isTablet? "50%" : "90%",
        alignSelf: "center",
        justifyContent: 'center',
        alignItems:'center'
    },
    btnTxt: {
        fontFamily: Units.ftFamily.KarlaB,
        fontSize: Units.isTablet? Units.ftSizes.big: Units.ftSizes.medium,
        letterSpacing: 5,
        color: EDColors.gradient[0],
    },
})
export default OutlineButton;