import React from "react";

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// import bottom's main screens
import StudyScreen from './screens/study';
import AnswerScreen from './screens/answer';
import PracticeScoreScreen from './screens/practiceScore';
import ReviewScreen from './screens/review';

const mainStack = new createStackNavigator();

function MainStack() {
    return (
        <NavigationContainer>
            <mainStack.Navigator>
                <mainStack.Screen name = "Study" component = {StudyScreen} options = {{headerShown: false}}/>
                <mainStack.Screen name = "Answer" component = {AnswerScreen} options = {{headerShown: false}} />
                <mainStack.Screen name = "Practice Score" component = {PracticeScoreScreen} options = {{headerShown: false}} />
                <mainStack.Screen name = "Review" component = {ReviewScreen} options = {{headerShown: false}} />
            </mainStack.Navigator>
        </NavigationContainer>
    )
};

export default MainStack;