import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    ActivityIndicator,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';

import { useMediaQuery } from 'react-responsive';
import { CircularProgressComponent, GradientButton3, TransButton, TopBar, LinearlineProgress } from '../../components';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';
import { getRenderDataForPracticeResult } from '../../utils/helper';
import styles from './style';

const PracticeScore = ({ navigation, route }) => {
    const { selectedLevel, sectionData } = useSelector( state => state.LevelReducer );
    const { score, data } = route.params ;

    const [correctCnt, setCorrectTotal] = useState(0);
    const [renderData, setRenderData] = useState(null);
    const isMobile = useMediaQuery({
        query: "(max-width: 680px)",
    });

    useEffect(() => {
        if( selectedLevel === undefined ) {
            navigation.pop();
        }
        const tpRenderData = getRenderDataForPracticeResult(data, sectionData, selectedLevel.LevelId);
        setRenderData(tpRenderData.data);
        setCorrectTotal(tpRenderData.total);
        return () => {
            setRenderData(null);
        }
    }, []);

    const _handleNext = () => {
        navigation.pop();
        navigation.navigate("Answer");
    }

    const _handleReview = () => {
        navigation.navigate("Review");
    }

    const _handleChart = async () => {
        navigation.navigate("Dashboard");
    }

    const _renderQNo = ({item}) => {
        return (
            <Text style = {item.status ? styles.qNoTextSucceed : styles.qNoTextFailed} >{item.QNo}</Text>
        )
    }

    const _renderItem = ({item, index}) => {
        if( item === null || item === undefined ) return null;
        return (
            <View style={index%2 ? styles.itemCon: styles.itemCon2}>
                <View style={{width: isMobile? 80: 100}}>
                    <Text style={styles.txt}>{item.name}</Text>
                    <Text style={styles.txt}>{`(${item.SectionId})`}</Text>
                </View>
                <View style={[styles.progressCon, {width: isMobile? '50%': '65%'}]}>
                    <LinearlineProgress
                        progress={item.percent}
                        gradient={item.percent > 50 ? EDColors.progress : EDColors.progress2}
                        width={5}
                        background = {EDColors.lightGray}
                    />
                    <View style={styles.qNosContainer}>
                        <FlatList
                            data = {item.questionNo}
                            keyExtractor = {(_, index) => index.toString()}
                            numColumns={isMobile ? 3 : 10}
                            renderItem = {_renderQNo}
                        />
                    </View>
                </View>
                <View style={styles.percentCon}>
                    <Text style={styles.txt2}>{item.percent}%</Text>
                </View>
            </View>
        )
    }

    return (
        <View style = {styles.container}>
            <View style = {styles.topContainer}>
                <TopBar
                    title = {selectedLevel === null || selectedLevel === undefined? "" : `${selectedLevel.Name}:`}
                    // flag="chart-bar"
                    // onRightPress = {_handleChart}
                    subtitle = "Practice Test"
                />
                <View style={{width: "100%", height: 15, backgroundColor: EDColors.topbar, marginTop: -1}}></View>
            </View>
            <View style = {styles.contentContainer}>
                {!score ?
                    <ActivityIndicator size="large" color = {EDColors.third}/>:
                    <View style={isMobile? [styles.body, {paddingHorizontal: "5%"}]: styles.body}>
                        <View style={styles.scoreSection}>
                            <View>
                                <Text style={styles.scoreTitleTxt}>Test Result</Text>
                                <Text style={score < Units.passPercent? styles.failTxt: styles.passTxt} >{score < Units.passPercent? `FAILED` : `PASSED`}</Text>
                                <Text style={styles.cntTxt} >{`${correctCnt}/${Units.practiceQuesCnt[`level${selectedLevel.LevelId}`]}`} Correct</Text>
                            </View>
                            <CircularProgressComponent
                                size={Units.isTablet? 150: Units.isIOS? 100: 80}
                                font={Units.ftSizes.extra}
                                fill={parseInt(score)}
                            />
                        </View>
                        <View style={styles.reviewSection}>
                            <View style={styles.subpoolCon}>
                                <Text style={styles.poolTxt}>Subpool scoring</Text>
                            </View>
                            <FlatList
                                data={renderData}
                                keyExtractor={(item, index) => "" + index.toString()}
                                renderItem={_renderItem}
                                showsVerticalScrollIndicator={false}
                            />
                        </View>
                        <View style={styles.actionSection}>
                            <View style={{width: isMobile? "100%" : "50%"}} key="bottom">
                                <GradientButton3
                                    text={"start new exam"}
                                    handlePress={_handleNext}
                                />
                            </View>
                            <View style={{height: 10}}></View>
                            <View style={{width: isMobile? "100%" : "50%"}} key="top">
                                <TransButton
                                    text={"review questions"}
                                    handlePress={_handleReview}
                                />
                            </View>
                        </View>
                        <View style={{height: 40}}></View>
                    </View>
                }
            </View>
        </View>
    );

};

export default PracticeScore;
