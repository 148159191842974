import React from 'react';
import {
    View,
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

const LineProgress =({progress, gradient, background, width})=> {

    return (
        <View style={[{backgroundColor: background, height: width, borderRadius: width/2+1, opacity: .8}]}>
            <LinearGradient
                start={{x: 0, y: 0}} end={{x: 1, y: 1.0}}
                style={{flex: 1, height: width, width: `${progress}%`, borderRadius: width/2 + 1}}
                colors={gradient}
            />
        </View>
    );
}
export default LineProgress;