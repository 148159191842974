import { t } from '../actions/Type';

const initialState = {
    levelLoading:  false,
    selectedLevel: null,
    levelData: null,
    error: null,

    sectionData: null,
    selectedSection: null,

    reportsDataForLevel: null,
    quesData: null,

    resultForQues: null,
    quizType: "general",

    reportsPracDataForLevel: null,
    reviewData: null,

    inAppReview: false,
    offlineFiles: null,
}

export default function( state = initialState, action ) {
    switch( action.type ) {
        case t.LEVEL_LOAD:
            return {
                ...state,
                levelLoading: true,
            };
        case t.LEVEL_LOAD_SUCCESS:
            return {
                ...state,
                levelLoading: false,
                levelData: action.payload,
            };
        case t.LEVEL_LOAD_FAILED:
            return {
                ...state,
                levelLoading: false,
                error: action.payload,
            };
        case t.LEVEL_ONE_SELECT:
            //console.log(action.payload);
            return {
                ...state,
                selectedLevel: action.payload,
            };
        case t.SECTION_LOAD_SUCCESS:
            return {
                ...state,
                sectionData: action.payload,
            };
        case t.SECTION_ONE_SELECT:
            return {
                ...state,
                selectedSection: action.payload,
            }
        case t.REPORTS_LEVEL_LOAD:
            return {
                ...state,
                reportsDataForLevel: action.payload,
            }
        case t.REPORTS_PRACTICE_LEVEL_LOAD:
            return {
                ...state,
                reportsPracDataForLevel: action.payload,
                quizType: action.quizType,
            }
        case t.QUESTION_DATA_LOAD:
            return {
                ...state,
                quesData: action.payload,
                quizType: action.quizType,
            }
        case t.REPORTS_QUESTION_LOAD:
            return {
                ...state,
                resultForQues: action.payload,
            }
        case t.SET_REVIEW_DATA:
            return {
                ...state,
                reviewData: action.payload,
            }
        case t.QUESTION_LOAD_START:
            return {
                ...state,
                quesData: null,
            }
        case t.SEND_IN_APP_REVIEW: 
            return {
                ...state,
                inAppReview: action.payload,
            }
        case t.OFFLINE_FILES:
            return {
                ...state,
                offlineFiles: action.payload,
            }
        default:
            return state;
    }
}