
import { StyleSheet } from 'react-native';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: EDColors.white,
    },
    topContainer: {
        width: '100%',
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
        marginBottom: 20,
        // position: 'fixed',
        // zIndex: 9,
    },
    contentContainer: {
        position: 'relative',
    },
    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    lessonCon:{
        paddingTop: 5,
        backgroundColor: EDColors.topbar,
    },
    progressCon: {
        backgroundColor: EDColors.topbar,
        justifyContent: "center",
        paddingVertical: 10,
        paddingHorizontal: "20%",
        marginTop: -1,
    },
    lessonTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.big,
        color: EDColors.gradient[2],
        alignSelf:'center',
    },
    noText: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        color: EDColors.progress[2],
        textAlign: 'center',
        marginRight: 5,
    },
    body:{
        flex: 1,
        width: '100%',
        paddingHorizontal: "20%",
        paddingVertical: 10,
    },
    question: {
        fontFamily: Units.ftFamily.KarlaB,
        fontSize: Units.ftSizes.big,
        fontWeight: 700,
        lineHeight: 23,
        marginBottom: 10,
        paddingVertical: 10,
        color: EDColors.third,
    },
    quesTxt: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.answer,
        lineHeight: 23,
        paddingVertical: 10,
        color: EDColors.text,
    },
    quesTxt2: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.answer,
        lineHeight: Units.isTablet? 25: 20,
    },
    optionCon:{
        paddingVertical: 20,
        paddingHorizontal: 10,
    },
    circle:{
        width: 16,
        height: 16,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: EDColors.lightBlue,
        justifyContent: "center",
        alignItems: "center",
    },
    innerCircle: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: EDColors.lightBlue,
    },
    answerCon: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderRadius: 5,
        minHeight: 60,
        borderColor: EDColors.itembdr
    },
    indexTxt: {
        fontFamily: Units.ftFamily.MontserratB,
        padding: Units.isTablet? 10: 5,
    },
    ansTxtCon: {
        padding: 10,
        width: '85%',
    },
    ansTxtConMobile: {
        padding: 10,
        width: '85%',
    },
    imgQues: {
        alignSelf: 'center',
        width: '90%',
        height: 300,
    },
    letterCon: {
        width: 25,
        alignItems: "center",
        paddingVertical: Units.isTablet? 10: 0,
    },
    otherModalCon:{
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: EDColors.modalbk,
        zIndex: 99,
    },
    line: {
        width: 1,
        height: 40,
        backgroundColor: EDColors.lightGray,
        marginLeft: 10,
        marginRight: 10
    }
});

export default styles;
