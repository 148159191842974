import { StyleSheet } from 'react-native';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: EDColors.topbar,
    },
    mainbody: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 20,
    },
    body: {
        width: '100%',
        flex: 1,
        marginTop: -20
    },
    flatCon: {
        flexDirection: "row",
        justifyContent: "center"
    },
    subCon: {
        flex: 1,
    },
    itemCon: {
        flex : 1,
        padding: 20,
        alignItems: 'center',
        backgroundColor: EDColors.topbar,
        marginVertical: 20,
        minWidth: 280,
        maxWidth: 500,
        alignSelf: 'center',
        borderRadius: 25,
        shadowOffset: { width: 0, height: 2 },
        shadowColor: EDColors.topbar,
        shadowOpacity: .5,
        shadowRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.gradient[0],
    },
    moitemCon: {
        flex : 1,
        padding: 20,
        alignItems: 'center',
        backgroundColor: EDColors.topbar,
        marginVertical: 20,
        width: '90%',
        height: "100%",
        minWidth: 280,
        maxWidth: 500,
        minHeight: 420,
        alignSelf: 'center',
        borderRadius: 25,
        shadowOffset: { width: 0, height: 2 },
        shadowColor: EDColors.topbar,
        shadowOpacity: .5,
        shadowRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.gradient[0],
        position: 'absolute',
        top: -100,
    },
    buttonCon: {
        flex: 1,
        width: "90%",
        minWidth: 260,
        marginTop: 5,
        // marginBottom: 10,
        justifyContent: "flex-end",
    },
    multiSlider: {
        fontFamily: Units.ftFamily.MontserratR,
        marginTop: 20,
        textAlign: "center",
        alignItems: 'center',
    },
    levelitemCon: {
        paddingBottom: 10,
        marginHorizontal: 15,
        justifyContent: "flex-end",
    },
    actLevelitemCon: {
        paddingBottom: 10,
        marginHorizontal: 15,
        borderBottomWidth: 2,
        justifyContent: "flex-end",
        borderBottomColor: EDColors.secondary,
    },
    markImg: {
        width: 250,
        height: 250,
    },
    levelTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.secondary,
        fontSize: Units.ftSizes.big,
    },
    actlevelTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.third,
        fontSize: Units.ftSizes.big,
    },
    nameTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.fourth,
        fontSize: Units.ftSizes.extra,
        lineHeight: 45,
        textAlign: "center",
        paddingTop: Units.isTablet? 20 : 10,
    },
    nameTxt2: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.primary,
        fontSize: Units.ftSizes.medium,
        letterSpacing: 2,
        paddingTop: Units.isTablet? 20: 10,
    },
    nameTxt3: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.primary,
        fontSize: Units.ftSizes.medium,
        letterSpacing: 2,
        paddingTop: Units.isTablet? 20: 10,
        paddingBottom: Units.isTablet? 20: 10,
    },
    delModalCon: {
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    },
    waitModalCon: {
        flex: 1,
        backgroundColor: EDColors.topbar,
        opacity: .5,
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    },
    otherModalCon:{
        flex: 1,
        width: Units.screenSize.W,
        height: Units.screenSize.H,
        paddingTop: "30%",
        position: "absolute",
        alignItems: "center",
        backgroundColor: EDColors.modalbk,
    },
    otherModalCon2: {
        // paddingTop: "30%",
        width: Units.screenSize.W,
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    }
});

export default styles;