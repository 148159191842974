import { Units } from "../constants/Unit";

export const validateEmail=(email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validationEmail=email => {
  return validateEmail(email)? true: false;
}

export const validationDigit=email => {
  if (validatedigit(email)) {
    return true
  }
  alert("The password should be minimum of 6-digits!");
  return false;
}

export const exchangeElements = (data, index) => {
  var ret = [];
  if( index === 0 )
    ret = [data[1], data[0], data[2]];
  else if( index === 1 )
    ret = data;
  else if( index === 2)
    ret = [data[0], data[2], data[1]];
  return ret;
}

export const validatedigit=(inputtxt)=>{
  var phoneno = /^\d{6}$/;
  if( phoneno.test(String(inputtxt).toLowerCase())){
    return true;
  } else {
    return false;
  }
}

export const validateNumber=(inputtxt)=>{
  var phoneno = /^\d{11}$/;
  if(phoneno.test(String(inputtxt).toLowerCase())){
    return true;
  } else {
    alert("Phone number is incorrect!");
    return false;
  }
}


export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const shuffle2 = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
export const getRenderDataForPracticeResult = (ques, section, lid) => {
  // return new Promise ((resolve, reject) => {
    let retData = [];
    const rules = Units.ruleForSelecting[`level${lid}`];
    const secCnt = [rules["section1"], rules["section2"], rules["section3"], rules["section4"], rules["section5"], rules["section6"], rules["section7"], rules["section8"], rules["section9"], rules["section10"]];
  
    let t=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let qNos = [[], [], [], [], [], [], [], [], [], []]
    let total = 0;
    let tpCnt = 0;
    ques.forEach(quesitem => {
      tpCnt++;
      if( quesitem.Correctanswer === quesitem.userAnswer || quesitem.CorrectAnswer === quesitem.userAnswer ){
        total++;
        const insertItem = {
          status: 1,
          QNo: quesitem.QNo
        }
        if( quesitem.SectionId === section[0].SectionId){
          t[0]++;
          qNos[0].push(insertItem);
        }
        if( quesitem.SectionId === section[1].SectionId ){
            t[1]++;
            qNos[1].push(insertItem);
        }
        if( quesitem.SectionId === section[2].SectionId ){
            t[2]++;
            qNos[2].push(insertItem);
        }
        if( quesitem.SectionId === section[3].SectionId ){
            t[3]++;
            qNos[3].push(insertItem);
        }
        if( quesitem.SectionId === section[4].SectionId ){
            t[4]++;
            qNos[4].push(insertItem);
        }
        if( quesitem.SectionId === section[5].SectionId ){
            t[5]++;
            qNos[5].push(insertItem);
        }
        if( quesitem.SectionId === section[6].SectionId ){
            t[6]++;
            qNos[6].push(insertItem);
        }
        if( quesitem.SectionId === section[7].SectionId ){
            t[7]++;
            qNos[7].push(insertItem);
        }
        if( quesitem.SectionId === section[8].SectionId ){
            t[8]++;
            qNos[8].push(insertItem);
        }
        if( quesitem.SectionId === section[9].SectionId ){
            t[9]++;
            qNos[9].push(insertItem);
        }
      } else {
        const insetItem = {
          status: 0,
          QNo: quesitem.QNo
        };
        if( quesitem.SectionId === section[0].SectionId){
          qNos[0].push(insetItem);
        }
        if( quesitem.SectionId === section[1].SectionId ){
          qNos[1].push(insetItem);
        }
        if( quesitem.SectionId === section[2].SectionId ){
          qNos[2].push(insetItem);
        }
        if( quesitem.SectionId === section[3].SectionId ){
          qNos[3].push(insetItem);
        }
        if( quesitem.SectionId === section[4].SectionId ){
          qNos[4].push(insetItem);
        }
        if( quesitem.SectionId === section[5].SectionId ){
          qNos[5].push(insetItem);
        }
        if( quesitem.SectionId === section[6].SectionId ){
          qNos[6].push(insetItem);
        }
        if( quesitem.SectionId === section[7].SectionId ){
          qNos[7].push(insetItem);
        }
        if( quesitem.SectionId === section[8].SectionId ){
          qNos[8].push(insetItem);
        }
        if( quesitem.SectionId === section[9].SectionId ){
          qNos[9].push(insetItem);
        }
      }
    });
    if( tpCnt === ques.length ){
      let count = 0;
      t.forEach((cnt, index) => {
        retData.push({
          name: "Lesson " + (count+1),
          totalCnt: secCnt[count],
          correctCnt: cnt,
          percent: parseInt(100 * cnt / secCnt[count]),
          SectionId: section[index].SectionId,
          questionNo: qNos[index] 
        });
        count++;
      });
      return {
        total: total,
        data: retData,
      };
    }
  // })
}

const strMonths = ["Jan", "Feb", "Mar", "Apl", "May", "Jun", "Jly", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const getCurrentDayTime = () => {
  var date = new Date();
  var day = date.getDate();
  var mon = strMonths[date.getMonth()];
  var y = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  return mon + " " + day + ", " + y + " " + h + ":" + m + formatAMPM(date);
}

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var ampm = hours>=12 ? 'pm' : 'am';
  return ampm;
}

export const getAveScore = (arr) => {
  if( arr === null ) return 0;
  if( arr.length === 0 ) return 0;
  const division = arr.length > 3 ? 3: arr.length;
  let score = 0;
  for( let i = 0 ; i < 3 ; i++ ){
    if( i < arr.length )
      score += arr[i].Score;
  }

  return parseInt(score/division);
}

export const getPassedCnt = (arr) => {
  if( arr === null ) return 0;
  if( arr.length === 0 ) return 0;
  const temp = arr.filter((_) => _.Pass === 1);
  return temp.length;
}
