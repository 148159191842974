import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    Linking,
    
} from 'react-native';
import MultiSwitch from 'react-multi-switch-toggle'
import { FlatList } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';

import { selectOneLevel } from '../../actions/LevelAction';
import { useAction } from '../../actions/useAction';
import { GradientButton4, ScreenHeader } from '../../components';
import { Assets } from '../../constants/Images';
import styles from './style';
import { EDColors } from '../../constants/Colors';

import { getLevel } from '../../actions/OfflineAction';
import firebase from 'firebase/app';
import 'firebase/auth';
import { authInfo } from '../../constants/Config';
import { Units } from '../../constants/Unit';

const imgs = [Assets.technician, Assets.general, Assets.amateur];
const subUrls = ["technician", "general", "extra"];

const Study = ({ navigation }) => {
  const [renderData, setRenderData] = useState([]);
  const [curIndex, setCurrentIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [bOneItem, setOneItem] = useState(true);

  const selectLevel = useAction(selectOneLevel);

  const isMobile = useMediaQuery({
    query: "(max-width: 520px)",
  });
/*
  const indexedDB = window.indexedDB;
  if (!indexedDB) {
    console.log("IndexedDB could not be found in this browser.");
  } else {
    const request = indexedDB.open("firebaseLocalStorageDb", 1);
    request.onsuccess = function (event) {
      const db = request.result;
      console.log("connected to DB", db)
    }
  }
*/

  useEffect(() => {
    firebase.auth().signInWithEmailAndPassword(authInfo.email, authInfo.password).then((user) => {
      if( user.user ) {
        checkUrl();
      }
    }).catch((err) => {})
  }, [])

  const checkUrl = () => {
    Linking.getInitialURL().then((url) => {
      const lastItem = url.substring(url.lastIndexOf('/') + 1);
      let index = -1;
      if( lastItem === subUrls[0]){
        index = 0;
      } else if( lastItem === subUrls[1] ){
        index = 1;
      } else if( lastItem === subUrls[2] ) {
        index = 2;
      }
      if( index !== -1 ) {
        setCurrentIndex(index);
        getLevelData(index);
        setOneItem(false);
      } else {
        if(lastItem.includes('.') || lastItem === ""){
          setCurrentIndex(0);
          setOneItem(true);
          getLevelData(0);
        } else {
          setLoading(false);
          alert("Invalid Url!" + '\n' + "Please use like this https://.../#/technician, general, extra");
        }
      }
    })
  }

  const getLevelData = async (_idx) => {
    getLevel().then((level) => {
      setData(level, _idx);
    }).catch((err) => {
      alert("Please login or check your network status.\n" + err);
      navigation.pop();
    });
  }

  const setData = (arr, _idx) => {
    //console.log(arr);
    //console.log(_idx);
    setRenderData(arr);
    if(_idx == 0)
      selectLevel(arr[3]);
    else
      selectLevel(arr[_idx]);
    setLoading(false);
  }

  const _handleItem = () => {
    navigation.navigate("Answer");
  }

  const _handleSelectItem = (index) => {
    setCurrentIndex(index);
    if(index == 0){
      selectLevel(renderData[3]);
    }
    else{
      selectLevel(renderData[index]);
    }
  }

    const _renderItem = ({item, index}) => {
      if( item === null || item === undefined ){
        return null;
      }
      if(index > 2) // This is so that the "ghost" levels are not displayed
        return;
      return(
        <TouchableOpacity style = {index === curIndex? styles.actLevelitemCon: styles.levelitemCon} onPress = {() => _handleSelectItem(index)} key = {item.id}>
          <Text style={index === curIndex ? styles.levelTxt : styles.actlevelTxt}>Level {index+1}</Text>
        </TouchableOpacity>
      );
    }

  const toggleSwitch = (selectedItem) => {
    //console.log(selectedItem);
    //console.log(isEnabled);
    if(selectedItem){ // If the toggle switch is on the new test option, we set the level to be '4'
      _handleSelectItem(3);
    }
    else{
      _handleSelectItem(0); // Otherwise stay on level '1'
    }
    setCurrentIndex(0); // Set the index back to 0 so that visually levels 1 and 4 look the same
  }
  return (
    <View style = {styles.container}>
      <View style={styles.mainbody}>
        <ScreenHeader text = {isMobile && !bOneItem? "": "Amateur Radio License Practice Test"} isMobile={isMobile}/>
        {curIndex !== -1 && <View style={styles.body}>
          {loading || renderData.length === 0? 
            <ActivityIndicator size="large" color = {EDColors.primary}/>:
            <>
              {bOneItem && <View style={styles.flatCon}>
                <View>
                  <FlatList
                    data = {renderData}
                    keyExtractor = {(item, index) =>"" + index.toString()}
                    renderItem = {_renderItem}
                    horizontal={true}
                    showsVerticalScrollIndicator = {false}
                  />
                </View>
              </View>}
              <View  style={styles.subCon}>
                <View style = {isMobile && !bOneItem ? [styles.moitemCon, {width: "90%"}] : [styles.itemCon, { width: isMobile ? "90%": "70%"}]} >
                  <View style={{flex: 1.5, alignItems:"center"}}>
                    {bOneItem && <Text style = {styles.nameTxt2} numberOfLines={1}>{renderData[curIndex].ShortDesc}</Text> }
                    <Text 
                      style={isMobile? [styles.nameTxt, {fontSize: Units.mobftSizes.extra}]:[styles.nameTxt]}
                      numberOfLines={1}
                    >
                      {renderData[curIndex].Name}
                    </Text>
                    {isMobile && !bOneItem && <Text style={styles.levelTxt}>{`(Practice Test)`}</Text>}
                  </View>
                  <View style={{flex: 2, justifyContent: "center",}}>
                    <Image
                      source = {imgs[curIndex]} 
                      style = {styles.markImg} 
                      resizeMode = "contain"
                    />
                  </View>
                  <View style={styles.buttonCon}>
                    <GradientButton4 text="let's start" handlePress= {() => _handleItem()}/>
                  </View>
                </View>
              </View>
            </>
          }
        </View>}
      </View>
    </View>
  )
};

export default Study;
